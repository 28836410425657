
@font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
   url("./fonts/gotham/Gotham-Light.otf") format("truetype");
 }
@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
   url("./fonts/gotham/GothamMedium.ttf") format("truetype");
 }
@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
   url("./fonts/gotham/Gotham-Bold.otf") format("truetype");
  font-weight: bold;
 }
@font-face {
  font-family: "GothamBlack";
  src: local("GothamBlack"),
   url("./fonts/gotham/Gotham-Black.otf") format("truetype");
  font-weight: 900;
 }
 