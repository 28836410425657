.c-header{
    width: 100%;
    height: 208px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 200;
    left: 0;
    z-index: 9999;
    color: #fff;
}
.c-header__logo{
    width: 224px;
    margin-left: 79px;
}
.c-header__nav__desktop{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-right: 70px;
}
.c-header__menu__container{
    display: none;
}
.c-header__menu-icon{
    width: 80%;
    height: 4px;
    background-color: #ffff;
    border-radius: 50px;
}
.c-header__close-icon-left{
    width: 80%;
    height: 4px;
    background-color: #ffff;
    border-radius: 50px;
    transform: rotate(45deg);
}
.c-header__close-icon-right{
    width: 80%;
    height: 4px;
    background-color: #ffff;
    border-radius: 50px;
    transform: translateY(-12px) rotate(-45deg);
}
.c-header__nav__item{
    cursor: pointer;
   text-decoration: none;
   color: #fff;
}
.c-header__nav__item:hover{
    font-family: GothamBold;
   border-bottom: solid 2px #fff;
}
.c-header__nav__item.active{
    font-family: GothamBold;
    border-bottom: solid 2px #fff;
}
.c-header__nav__submenu{
    width: 200px;
    position: absolute;
    background-color: #333333;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 15px;
    margin-top: 10px;
}

@media (max-width: 1100px) {
    .c-header__menu__container{
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        margin-right: 40px;
    }
    .c-header__nav__desktop{
        display: none;
    }
    .c-header__nav__mobile{
        width: 200px;
        position: absolute;
        right: 30px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        background-color: #333333;
        padding: 20px 10px;
    }
    .c-header__nav__submenu{
        width: 100px;
        position: static;
        background-color: #333333;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 15px;
        margin-top: 10px;
    }
  }
  @media (max-width: 1100px) {
    .c-header{
        width: 100%;
        height: 68px;
        align-items: center;
    }
    .c-header__logo{
        width: 143px;
        margin-left: 20px;
    }
    .c-header__menu__container{
        margin-right: 20px;
    }
  }